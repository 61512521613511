.main__footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 50px;
  background-color: #e9ecef;
}

.footer__section {
  display: flex;
  flex-direction: column;
}

.footer__title {
  font-size: 20px;
  font-weight: bold;
}

@media screen and (max-width: 800px) {
  .main__footer {
    flex-direction: column;
    align-items: center;
  }
}
