.blog-post-detail {
  display: grid;
  grid-template-rows: auto 1fr;
  padding: 5vw; /* Padding to create space around the content */
  margin: 0 auto; /* Center the container horizontally */
  width: 90%; /* Limit the width of the container */
  max-width: 1200px; /* Set a maximum width for larger screens */
  font-family: "Open Sans", sans-serif;
}

/* Header container styling */
.blog-post-detail .header-container {
  position: relative; /* Ensure relative positioning for child elements */
  height: 35vh; /* Default height for the header container */
}

/* Styling for the post title */
.blog-post-detail .header-container h1 {
  position: absolute; /* Absolute positioning for better control */
  z-index: 2; /* Ensure the title is above the image */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center the title perfectly */
  font-size: 2em; /* Increase font size for better visibility */
  font-weight: 900; /* Adjust font weight for emphasis */
  color: #fff; /* Set text color to ensure visibility against the image */
  text-align: center; /* Align text to the center */
}

/* Styling for the post image */
.blog-post-detail .header-container img {
  display: block; /* Make the image a block element */
  width: 100%; /* Ensure the image fills the container width */
  height: 100%; /* Ensure the image fills the container height */
  object-fit: cover; /* Maintain aspect ratio and cover the container */
  border-radius: 5px; /* Apply border radius for rounded corners */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15); /* Add shadow for depth */
}

/* Overlay to improve text readability over the image */
.blog-post-detail .header-container::after {
  content: ""; /* Create a pseudo-element for the overlay */
  position: absolute; /* Absolute positioning to cover the image */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  border-radius: 5px; /* Apply border radius to match image */
  pointer-events: none; /* Ensure overlay does not interfere with content */
}

/* Content section styling */
.blog-post-detail .content {
  padding-top: 2em; /* Add spacing between header and content */
}

/* Styling for headings */
.blog-post-detail h2 {
  font-size: 1.8em; /* Increase font size for headings */
  margin-bottom: 20px; /* Add spacing between headings and paragraphs */
  color: #555; /* Set heading color for contrast */
}

/* Styling for paragraphs */
.blog-post-detail p {
  font-size: 1.2em; /* Adjust font size for paragraphs */
  margin-bottom: 20px; /* Add spacing between paragraphs */
  color: #666; /* Set paragraph color for readability */
}

/* Styling for links */
.blog-post-detail p a {
  color: red; /* Set link color */
  text-decoration: none; /* Remove default underline */
  transition: color 0.3s; /* Add transition for smoother hover effect */
}

/* Hover effect for links */
.blog-post-detail p a:hover {
  color: #0056b3; /* Change link color on hover */
  text-decoration: underline; /* Add underline on hover */
}

/* Media queries for responsiveness */
@media screen and (max-width: 768px) {
  /* Adjustments for smaller screens */
  .blog-post-detail .header-container {
    height: 25vh; /* Reduce header height for smaller screens */
    width: 90%; /* Adjust width for better responsiveness */
    margin: 0 auto; /* Center the container horizontally */
  }

  /* Adjustments for post title on smaller screens */
  .blog-post-detail .header-container h1 {
    font-size: 1em; /* Decrease font size for smaller screens */
  }

  /* Adjustments for post image on smaller screens */
  .blog-post-detail .header-container img {
    width: 100%; /* Ensure image fills the container width */
    height: 100%; /* Ensure image fills the container height */
  }

  /* Adjustments for paragraphs on smaller screens */
  .blog-post-detail p {
    font-size: 12px; /* Decrease font size for smaller screens */
  }
}
