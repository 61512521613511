.form-control,
.btn {
  box-sizing: border-box;
}

.form__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form__container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  background-color: #fff;
  border-radius: 4px;
  padding: 24px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.form__freeclass {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contact-field {
  margin-bottom: 20px;
}

.contact-field .form-control {
  width: 100%;
  padding: 10px;
  font-size: 22px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  color: black;
}

.form-group_label {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 10px;
}

.contact-field .form-control::placeholder {
  color: grey;
}

.btn-block {
  display: flex;
  justify-content: center;
}

.btn {
  width: 100%;
  padding: 10px;
  font-size: 20px;
  border: none;
  border-radius: 4px;
  background-color: #0e7e40;
  color: white;
  cursor: pointer;
}

.btn:hover {
  background-color: #09632e;
}

.lottie-container {
  width: 100%;
  height: 100%;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
}

.lottie-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
}
