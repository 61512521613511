.card-component {
  padding-top: 75px;
  padding-bottom: 50px;
  padding-left: 20px;
  padding-right: 20px;
  min-height: 80vh;
  display: flex; /* Makes the component a flex container */
  align-items: center; /* Centers content vertically */
  flex-direction: column; /* Stacks the content vertically */
  justify-content: space-around; /* Distributes extra space evenly */
}

.cards_wrapper {
  display: flex;
  align-items: center;
}

.card-module {
  display: flex;
  justify-content: space-around;
  align-items: center; /* Centers the flex items vertically */
  flex-wrap: wrap;
  padding: 20px;
}

.card-component__title {
  font-size: 34px;
  font-weight: 700;
  color: #333;
  text-align: center;
  margin-bottom: 15px;
  line-height: 1.2;
}

.card-component__subtitle {
  font-size: 24px;
  font-weight: 600;
  color: #666;
  text-align: center;
  margin-bottom: 15px;
  line-height: 1.2;
}

.card-component__description {
  font-size: 16px;
  font-weight: 400;
  color: #999;
  text-align: center;
  max-width: 1000px;
  margin: 0 auto;
  line-height: 1.6;
}

/* Styles for screens up to 1100px */
@media screen and (max-width: 1100px) {
  .card-component {
    padding-left: 10%;
    padding-right: 10%;
  }
}

/* Styles for screens up to 768px */
@media screen and (max-width: 768px) {
  .card-component {
    padding-left: 5%;
    padding-right: 5%;
  }

  .card-component__title {
    font-size: 28px;
  }

  .card-component__subtitle {
    font-size: 20px;
  }

  .card-component__description {
    font-size: 14px;
  }
}

/* Styles for screens up to 555px */
@media screen and (max-width: 555px) {
  .card-component {
    padding-left: 2%;
    padding-right: 2%;
  }

  .card-component__title {
    font-size: 24px;
  }

  .card-component__subtitle {
    font-size: 18px;
  }
}

/* Styles for screens up to 320px */
@media screen and (max-width: 320px) {
  .card-component__title {
    font-size: 20px;
  }

  .card-component__subtitle {
    font-size: 16px;
  }
}
