.footer__section {
  margin-right: 30px;
  color: #333;
}

.footer__title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #333;
}

.footer__section ul {
  list-style-type: none;
  padding: 0;
}

.footer__section ul li {
  line-height: 2;
  color: #666;
}

.footer__section ul li:hover {
  color: #000;
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .footer__section {
    margin-right: 0;
    margin-bottom: 30px;
  }
}
