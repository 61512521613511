/* Default styles (Mobile-first) */

.image-grid {
  padding-left: 20px; /* Reduce padding for mobile */
  padding-right: 20px;
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* Only 1 image per row on mobile */
  grid-gap: 10px; /* Reduce grid gap for mobile */
  box-sizing: border-box;
  padding-bottom: 50px;
}

.image-grid__item {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.image-grid__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.5s;
}

.image-grid__item:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  transition: 0.5s;
  opacity: 1;
  pointer-events: none;
}

.image-grid__item:hover:after {
  opacity: 0;
}

.image-grid__title {
  font-size: 24px; /* Reduce font size for mobile */
  font-weight: 700;
  color: #333;
  text-align: center;
  margin-bottom: 10px; /* Reduce margin for mobile */
  line-height: 1.2;
  padding-top: 50px; /* Reduce padding for mobile */
  padding-bottom: 25px;
}

/* Breakpoints */

@media screen and (min-width: 768px) {
  .image-grid {
    grid-template-columns: repeat(3, 1fr); /* 3 images per row on tablet */
    padding-left: 50px;
    padding-right: 50px;
  }
  .image-grid__title {
    font-size: 44px;
  }
}

@media screen and (min-width: 555px) and (max-width: 767px) {
  .image-grid {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* 2 images per row for medium screens */
  }
  .image-grid__title {
    font-size: 32px;
  }
  .image-grid {
    padding-bottom: 15px;
  }
  .image-grid__item:after {
    display: none;
  }
}

@media screen and (min-width: 320px) and (max-width: 554px) {
  .image-grid {
    padding-bottom: 15px;
  }
  .image-grid__item:after {
    display: none;
  }
}
