.main {
  min-height: 70vh;
  background-image: url("../images/stephanie-greene-1aB-1s4BIEo-unsplash.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #f8f9fa;
  padding-left: 100px;
  padding-top: 300px;
}

.main__container {
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px;
  margin: 0px;
}

.main__hero {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
  align-items: center;
}

.main__hero-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.main__hero-title {
  font-size: 90px;
  letter-spacing: 0.01em;
  margin: 0;
  padding-top: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main__hero-subtitle {
  font-size: 50px;
  margin: 0;
  padding-top: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main__hero-button {
  border-radius: 6px;
  background-color: #0e7e40;
  border-width: 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-size: 100%;
  height: 44px;
  line-height: 1.15;
  margin: 12px 0 0;
  outline: none;
  overflow: hidden;
  padding: 0 25px;
  text-align: center;
  text-transform: none;
  margin-top: 30px;
}

.main__hero-button:hover {
  background-color: #ffffff;
  background-color: #09632e;
}

/* Styles for screens up to 1100px */
@media screen and (max-width: 1100px) {
  .main {
    padding-left: 80px;
    padding-top: 200px;
  }

  .main__hero-title {
    font-size: 70px;
  }

  .main__hero-subtitle {
    font-size: 40px;
  }
}

/* Styles for screens up to 768px */
@media screen and (max-width: 768px) {
  .main {
    padding-left: 60px;
    padding-top: 150px;
  }

  .main__hero-title {
    font-size: 55px;
  }

  .main__hero-subtitle {
    font-size: 30px;
  }
}

/* Styles for screens up to 555px */
@media screen and (max-width: 555px) {
  .main {
    padding-left: 40px;
    padding-top: 120px;
    min-height: 0px;
  }

  .main__hero-title {
    font-size: 48px;
  }

  .main__hero-subtitle {
    font-size: 26px;
  }

  .main__hero-button {
    padding: 0 20px;
    font-size: 16px;
  }
}

/* Styles for screens up to 450px */
@media screen and (max-width: 450px) {
  .main {
    padding-left: 10px;
    padding-top: 50px;
  }

  .main__hero-title {
    font-size: 38px;
  }

  .main__hero-subtitle {
    font-size: 20px;
  }

  .main__hero-button {
    padding: 0 15px;
    font-size: 14px;
  }
}

/* Styles for screens up to 380px */
@media screen and (max-width: 380px) {
  .main {
    padding-left: 5px;
    padding-top: 45px;
  }

  .main__hero-title {
    font-size: 24px;
  }

  .main__hero-subtitle {
    font-size: 16px;
  }

  .main__hero-button {
    padding: 0 10px;
    font-size: 12px;
  }
}
