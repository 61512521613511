/* Container styling for the blog list */
.blog-list-container {
  max-width: 2000px; /* Limit the width of the container */
  margin-left: auto; /* Center the container horizontally */
  margin-right: auto; /* Center the container horizontally */
}

/* Styling for the list of blog posts */
.blog-list-list {
  display: grid; /* Use grid layout for the list of posts */
  grid-template-columns: repeat(
    2,
    minmax(300px, 1fr)
  ); /* Two columns with minimum width of 300px */
  padding: 20px 30px; /* Add padding around the list */
  grid-gap: 20px; /* Add gap between grid items */
  margin-top: 30px; /* Add space at the top of the list */
  margin-bottom: 30px; /* Add space at the bottom of the list */
}

/* Styling for the blog title */
.blog-title {
  display: flex; /* Use flexbox for flexibility */
  font-size: 5vw; /* Responsive font size based on viewport width */
  margin-bottom: 2vw; /* Add space below the title */
  margin-top: 2vw; /* Add space above the title */
  font-family: "Open Sans", sans-serif;
  font-weight: 900; /* Bold font weight */
  justify-content: center; /* Center the title horizontally */
}

/* Styling for each blog post excerpt */
.blog-post-excerpt {
  position: relative; /* Ensure relative positioning for child elements */
  flex: 0 1 calc(33.333% - 20px); /* Define flex properties for responsiveness */
  height: 300px; /* Set a fixed height for consistency */
  background-size: cover; /* Cover the entire background */
  background-position: center; /* Center the background image */
  margin-bottom: 20px; /* Add space below each excerpt */
  border-radius: 3px; /* Apply border radius for rounded corners */
  overflow: hidden; /* Hide any overflow content */
}

/* Styling for the content within each blog post */
.blog-post-content {
  position: relative; /* Ensure relative positioning for child elements */
  z-index: 2; /* Ensure content is above any background */
  color: white; /* Set text color */
  display: flex; /* Use flexbox for flexibility */
  flex-direction: column; /* Arrange content in a column */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  height: 100%; /* Occupy full height of the container */
  padding: 0 15px; /* Add padding around the content */
  font-family: "Open Sans", sans-serif;
}

/* Styling for the headings within each blog post */
.blog-post-content h2 {
  font-size: 2em; /* Set font size for headings */
  text-align: center; /* Center the headings */
  margin-bottom: 10px; /* Add space below headings */
  font-family: "Open Sans", sans-serif;
  font-weight: 800; /* Bold font weight */
  color: white; /* Set heading color */
  background-color: rgba(
    0,
    0,
    0,
    0.6
  ); /* Semi-transparent background for readability */
}

/* Styling for the "Read More" button */
.read-more-btn {
  display: inline-block; /* Display as inline block */
  background-color: #0e7e40; /* Set button background color */
  padding: 10px 20px; /* Add padding to the button */
  border-radius: 4px; /* Apply border radius for rounded corners */
  transition: background-color 0.3s; /* Add transition for smoother hover effect */
  text-decoration: none; /* Remove default underline for links */
  color: white; /* Set button text color */
  font-weight: 600; /* Set font weight for button text */
  font-size: 0.8em; /* Set font size for button text */
}

/* Hover effect for the "Read More" button */
.read-more-btn:hover {
  background-color: #09632e; /* Change background color on hover */
  color: white; /* Change text color on hover */
}

/* Media queries for responsive design */

/* Mobile styles */
@media (max-width: 768px) {
  .blog-title {
    font-size: 45px; /* Adjust font size for smaller screens */
  }

  .blog-post-excerpt {
    flex: 0 1 100%; /* Adjust excerpt width for smaller screens */
    margin-bottom: 15px; /* Reduce margin below excerpt for smaller screens */
    height: 300px; /* Maintain fixed height for consistency */
  }

  .blog-list-container {
    padding: 20px 10px; /* Adjust padding for smaller screens */
  }

  .blog-post-content h2 {
    font-size: 1.2em; /* Adjust font size for headings on smaller screens */
  }

  .read-more-btn {
    padding: 8px 16px; /* Adjust padding for smaller screens */
  }

  .blog-list-list {
    display: flex; /* Switch to flex layout for smaller screens */
    flex-direction: column; /* Arrange posts in a column */
    padding: 20px 30px; /* Adjust padding for smaller screens */
    grid-gap: 20px; /* Add gap between grid items */
    margin-top: 30px; /* Add space at the top of the list */
    margin-bottom: 30px; /* Add space at the bottom of the list */
  }
}

/* Tablet styles */
@media (min-width: 769px) and (max-width: 1024px) {
  .blog-title {
    font-size: 55px; /* Adjust font size for tablet screens */
  }

  .blog-post-excerpt {
    flex: 0 1 calc(50% - 15px); /* Adjust excerpt width for tablet screens */
    height: 350px; /* Adjust excerpt height for tablet screens */
  }

  .blog-list-container {
    padding: 20px 20px; /* Adjust padding for tablet screens */
  }

  .blog-post-content h2 {
    font-size: 1.3em; /* Adjust font size for headings on tablet screens */
  }
}
