/* Default styles (Mobile-first) */

.review-component {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  margin: 10px; /* reduced margin */
  padding: 10px;
  border-radius: 4px;
  min-height: 50vh;
}

.review-component__title {
  font-size: 24px; /* Adjusted for smaller screens */
  font-weight: bold;
  margin-bottom: 15px; /* reduced margin-bottom */
}

.review-module {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* Breakpoints */

@media screen and (min-width: 768px) {
  .review-component__title {
    font-size: 44px;
  }
}

@media screen and (min-width: 555px) and (max-width: 767px) {
  .review-component__title {
    font-size: 32px;
  }
}

@media screen and (min-width: 320px) and (max-width: 554px) {
  .review-component {
    padding: 15px;
  }
  .review-component__title {
    font-size: 28px;
  }
}
