.card {
  width: 300px;
  margin: 20px;
  padding: 20px;
  border: 1px solid #e0e0e0;
  background-color: #fff;
  border-radius: 4px;
  text-align: center;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.card:hover {
  transform: scale(1.02);
}

.link {
  text-decoration: none;
  color: inherit;
}

.card__title {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  text-decoration: none;
}
.card__image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 4px;
}

.card__description {
  margin: 10px 0;
}

.card__button {
  margin-top: 20px;
  padding: 10px;
  background-color: #0e7e40;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.card__button:hover {
  background-color: #09632e;
}

.card__description {
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
}

.card__description li {
  margin-bottom: 5px;
}

/* Styles for screens up to 1100px */
@media screen and (max-width: 1100px) {
  .card {
    width: 270px;
  }

  .card__title {
    font-size: 22px;
  }
}

/* Styles for screens up to 768px */
@media screen and (max-width: 768px) {
  .card {
    width: 240px;
  }

  .card__title {
    font-size: 20px;
  }

  .card__image {
    height: 180px;
  }
}

/* Styles for screens up to 555px */
@media screen and (max-width: 555px) {
  .card {
    width: 195px;
  }

  .card__title {
    font-size: 18px;
  }

  .card__image {
    height: 160px;
  }
}

/* Styles for screens up to 320px */
@media screen and (max-width: 320px) {
  .card {
    width: 195px;
    margin: 10px 0;
  }

  .card__title {
    font-size: 16px;
  }

  .card__image {
    height: 140px;
  }
}
