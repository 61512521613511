.page {
  /* background-image: url("../images/fitness/stephanie-greene-1aB-1s4BIEo-unsplash.jpg"); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #f8f9fa;
  margin: auto;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  min-height: 100vh;
  color: #000;
}
