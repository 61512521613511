.footer__section {
  margin-right: 30px;
  color: #333;
}

.footer__title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #333;
}

.footer__logo {
  width: 100px;
  height: auto;
}

@media screen and (max-width: 800px) {
  .footer__section {
    margin-right: 0;
    margin-bottom: 30px;
  }
}
