/* Default styles (Mobile-first) */

.review {
  width: calc(
    100% - 40px
  ); /* Make the card stretch full width minus padding and margins */
  padding: 10px;
  margin: 10px;
  border: 1px solid #e0e0e0;
  background-color: #fff;
  border-radius: 4px;
  text-align: center;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.review__image {
  width: 80px; /* Reduced size for mobile */
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.review__name {
  margin-top: 8px; /* Adjusted for mobile */
  margin-bottom: 4px; /* Adjusted for mobile */
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.review__text {
  margin: 5px 0;
  font-style: italic;
}

/* Breakpoints */

@media screen and (min-width: 768px) {
  .review {
    width: 250px;
    height: auto; /* To avoid constraining the card's height */
    padding: 20px;
    margin: 20px;
  }
  .review__image {
    width: 100px;
    height: 100px;
    margin-bottom: 15px;
  }
  .review__name {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 555px) and (max-width: 767px) {
  .review {
    width: 60%; /* Adjust as needed */
  }
}

@media screen and (min-width: 320px) and (max-width: 554px) {
  .review {
    width: calc(100% - 30px); /* Leave a little margin */
  }
}
