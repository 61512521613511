.footer__section {
  margin-right: 30px;
  color: #333;
}

.footer__title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #333;
}

.footer__section p {
  line-height: 2;
  color: #666;
}

.footer__section a {
  color: #666;
  text-decoration: none;
}

.footer__section a:hover {
  color: #000;
}

@media screen and (max-width: 800px) {
  .footer__section {
    margin-right: 0;
    margin-bottom: 30px;
  }
}
