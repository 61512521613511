.privacy {
  display: grid;
  grid-template-columns: 1fr;
  min-height: 80vh;
  background-color: #e9ecef;
  font-family: "Open Sans", sans-serif;
  color: #333;
}

.privacy__image {
  position: relative;
  grid-column: 1 / span 1;
  overflow: hidden;
  z-index: 0;
  /* background-image: url("../images/fitness/bewakoof-com-official-mtOMSpOWxRI-unsplash.jpg"); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.privacy__image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.2)
  );
}

.privacy__title {
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  grid-column: 1 / span 1;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 24px; /* Adjusted font-size */
  font-weight: bold;
  color: white;
  height: 100%;
}

.privacy__title h3 {
  font-size: 16px; /* Adjusted font-size */
  font-weight: normal;
  color: #ddd;
  margin-top: 10px;
}

.privacy__content {
  grid-column: 1 / span 1; /* Adjusted grid-column */
  padding: 2em;
  font-size: 16px;
  line-height: 1.5;
  padding-top: 50px; /* Adjusted padding-top */
}

.privacy__content p {
  margin-bottom: 1em;
}

/* Media query to handle larger screens */
@media screen and (min-width: 768px) {
  .privacy {
    grid-template-columns: 1fr 1fr;
  }

  .privacy__title {
    font-size: 54px;
  }

  .privacy__title h3 {
    font-size: 24px;
  }

  .privacy__content {
    grid-column: 2 / span 1;
    padding-top: 100px;
  }
}
