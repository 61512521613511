.header {
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  background-color: rgba(233, 236, 239, 0.9);
  position: sticky;
  top: 0;
  z-index: 100;
}

.header__container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.header__menu {
  display: flex;
  align-items: center;
}

.header__menu-list {
  list-style-type: none;
  font-size: 16px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  margin: 0;
  display: flex;
}

.header__menu-list-item-link {
  text-decoration: none;
  padding: 12px 16px;
  color: #222222;
}

.header__menu-list-item-button {
  border-radius: 6px;
  background-color: #0e7e40;
  border-width: 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-size: 100%;
  height: 44px;
  line-height: 1.15;
  margin: 12px 0 0;
  margin-left: 50px;
  outline: none;
  overflow: hidden;
  padding: 0 25px;
  text-align: center;
  text-transform: none;
}

.header__menu-list-item-button:hover {
  background-color: #09632e;
}

.header__logo-image {
  height: 40px;
  width: auto;
}

@media screen and (max-width: 1150px) {
  .header {
    display: none;
  }
}

@media screen and (min-width: 1150px) {
  .header {
    display: block;
  }
}
