.profile_intro_container {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  padding-top: 35px;
  padding-bottom: 15px;
  margin: 0 auto;
}

.profile_intro_data {
  flex: 0 0 calc(50% - 30px);
  padding: 0 15px; /* Combined padding for both sides */
}

.profile_intro_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
}

.card_body {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid transparent;
  border-radius: 0.25rem;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  height: 100%; /* Ensure the card body occupies the full height */
  width: 400px;
}

.card_image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto; /* Center the image horizontally */
  border: 4px solid #3c3b3b; /* Add a white border around the image */
  box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%),
    0 2px 6px 0 rgb(206 206 238 / 54%);
}

.card_title {
  font-size: 35px; /* Adjust the font size as needed */
  font-weight: bold;
  margin: 10px 0; /* Add margin for spacing */
  text-align: center; /* Center the title */
}

.card_subtitle {
  font-size: 24px; /* Adjust the font size as needed */
  color: #777; /* Change the color to a suitable one */
  text-align: center; /* Center the subtitle */
}

.social_icons {
  display: flex; /* Display icons horizontally */
  justify-content: center; /* Center icons horizontally */
  margin-bottom: 10px; /* Add spacing between subtitle and icons */
}

.social_icons a {
  margin: 0 10px; /* Adjust the spacing between icons as needed */
  text-decoration: none;
  color: #333; /* Icon color */
  font-size: 24px; /* Adjust the font size as needed */
}

.profile_intro_contact {
  flex: 0 0 calc(50% - 30px); /* Adjusted width and added margin */
  padding: 0 15px; /* Combined padding for both sides */
}

.form_container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: flex-start;
  height: 100%; /* Ensure the form occupies the full height */
}

/* .contact_form_profilepage {
  width: 400px;
} */

.profile_intro_description {
  display: flex;
  margin: 0 auto;
  margin-top: 15px;
  width: 830px;
}

.profile_intro_description_text {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid transparent;
  border-radius: 0.25rem;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  padding: 20px; /* Adjust padding as needed */
}

.profile_intro_description_section {
  font-size: 24px; /* Adjust the font size as needed */
  color: #222121; /* Change the color to a suitable one */
  margin-top: 0px;
  font-weight: bold;
  margin: 10px 0; /* Add margin for spacing */
  text-align: center; /* Center the title */
}
.profile_intro_description_content {
  font-size: 18px; /* Adjust the font size as needed */
  color: #222121; /* Change the color to a suitable one */
  margin-top: 0px;
}

.profile_intro_services {
  display: flex;
  margin: 0 auto;
  margin-top: 15px;
  width: 830px;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid transparent;
  border-radius: 0.25rem;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.profile_intro_services_container {
  padding: 20px;
}

.profile_intro_services_package {
  font-size: 20px; /* Adjust the font size for service names */
  font-weight: bold;
  padding-left: 10px;
}

@media (max-width: 900px) {
  .profile_intro_data {
    /* flex: 0 0 calc(50% - 30px); */
    padding: 0 15px;
    margin-bottom: 10px;
  }

  .profile_intro_card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .profile_intro_contact {
    flex: none; /* Adjusted width and added margin */
    padding: 0 15px; /* Combined padding for both sides */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    align-items: center;
  }

  .profile_intro_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .card_body {
    position: relative;
    display: flex;
    flex-direction: row;
    padding-top: 15px;
    width: 400px;
    padding: 10px;
  }

  .profile_intro_description {
    display: flex;
    margin: 0 auto;
    margin-top: 15px;
    width: 90%;
  }

  .card_image {
    width: 90px;
    height: 90px;
    margin-right: 15px;
  }

  .profile_intro_services {
    width: 90%;
  }

  .contact_form_profilepage {
    width: none;
  }

  .form_container {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    align-items: center;
    height: 100%; /* Ensure the form occupies the full height */
  }

  .card_title {
    font-size: 25px; /* Adjust the font size as needed */
  }

  .card_subtitle {
    font-size: 15px; /* Adjust the font size as needed */
  }
}

@media (max-width: 432px) {
  .card_body {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 150%;
  }

  .card_image {
    width: 50px;
    height: 50px;
  }

  .profile_intro_card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .profile_intro_data {
    /* flex: 0 0 calc(50% - 30px); */
    padding: 0 15px;
    margin-bottom: 15px;
  }

  /* .contact_form_profilepage {
    width: 90%;
  } */
}
